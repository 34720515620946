/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */

/*
 * Brand
 * ======================================================================== */

/*
 * Typography
 * ======================================================================== */

/*
  * Layout
  * ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

/**
 * Custom application mixins available through out the app
 */

.root {
  height: 100%;
}

.wrap {
  min-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  transition: left 0.3s ease-in-out, right 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .wrap {
    left: 200px;
    right: 0;
  }
}

@media (max-width: 767.98px) {
  .sidebarOpen {
    left: 200px;
    right: -200px;
  }
}

.content {
  min-height: 100%;
  padding: 115px 30px 60px;
  background-color: #faf9f9;
}

