/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */

/*
 * Brand
 * ======================================================================== */

/*
 * Typography
 * ======================================================================== */

/*
  * Layout
  * ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

/**
 * Custom application mixins available through out the app
 */

.root {
  padding-top: 25vh;
  background-color: #7199c8;
}

.logo {
  width: 100%;
  justify-content: center;
  align-items: center;
  background: white;
}

.logo img {
  width: 100%;
  background-color: white;
  padding: 0px 20px 25px 20px;
}

.widget {
  max-width: 360px;
  margin: 0 auto;
  padding: 30px !important;
  margin-bottom: 50%;
}

.widget :global .form-control {
  font-size: 14px;
}

