@import '../../styles/app';

 .root {
   padding-top: 20vh;
   text-align: center;
 }
 
 .title {
   font-size: 80px;
 }
 