/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */

/*
 * Brand
 * ======================================================================== */

/*
 * Typography
 * ======================================================================== */

/*
  * Layout
  * ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

/**
 * Custom application mixins available through out the app
 */

.widget {
  display: block;
  position: relative;
  margin-bottom: 30px;
  padding: 15px 20px;
  background: #fff;
}

.title {
  margin-top: 0;
  margin-bottom: 1.5rem / 2;
  color: #444;
}

.title::after {
  display: block;
  clear: both;
  content: "";
}

