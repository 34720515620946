@import '../../styles/app';

.root {
  padding-top: 25vh;
  background-color: $snowrise-blue;
}


.logo {
  width: 100%;
  justify-content: center;
  align-items: center;
  background: white;

  img {
    width: 100%;
    background-color: white;
    padding: 0px 20px 25px 20px;
  }
}

.widget {
  max-width: 360px;
  margin: 0 auto;
  padding: 30px !important;
  margin-bottom: 50%;

  :global .form-control {
    font-size: 14px;
  }
}
