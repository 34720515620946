/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */

/*
 * Brand
 * ======================================================================== */

/*
 * Typography
 * ======================================================================== */

/*
  * Layout
  * ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

/**
 * Custom application mixins available through out the app
 */

.root {
  background: #7199c8;
}

.root :global .input-group {
  background-color: #faf9f9;
}

@media (max-width: 575.98px) {
  .root :global .input-group {
    display: none;
  }
}

.root :global .input-group input {
  background: transparent;
  border: none;
  width: 250px;
}

@media (max-width: 991.98px) {
  .root :global .input-group input {
    width: 100px;
  }
}

@media (max-width: 767.98px) {
  .root :global .input-group input {
    width: 175px;
  }
}

.root :global .input-group input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccbca;
  opacity: 1;
  /* Firefox */
}

.root :global .input-group input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccbca;
}

.root :global .input-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccbca;
}

.root :global .input-group input:hover,
.root :global .input-group input:active,
.root :global .input-group input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.root :global .input-group .input-group-append {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root :global .input-group .input-group-append i {
  font-size: 1.25rem;
  color: #cccbca;
}

.root :global .dropdown-menu {
  border: none;
  margin-top: 0.7rem;
}

.root :global .dropdown-item:active {
  background-color: #efedea;
  color: #4d4d4d;
}

.root :global .dropdown-item:focus {
  outline: none;
}

.root :global .dropdown-item a {
  display: block;
  color: #4d4d4d;
}

.root :global .dropdown-item a:hover {
  color: #4d4d4d;
  text-decoration: none;
  cursor: default;
}

.arrow {
  color: #ccc;
  transition: 0.3s;
  right: 0;
}

.arrowActive {
  transform: rotate(180deg);
}

.adminPhoto {
  width: 40px;
  height: 40px;
}

.headerIcon {
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 767.98px) {
  .headerIcon {
    display: none;
  }
}

.headerIcon :global .btn {
  background: #fff;
  border: none;
}

.headerIcon :global .btn:hover,
.headerIcon :global .btn:active,
.headerIcon :global .btn:focus,
.headerIcon :global .btn:focus:active {
  background: #fff;
  box-shadow: none !important;
  outline: none;
}

.headerIcon:nth-child(n + 2) {
  margin-left: 0.5rem;
}

.headerIcon:nth-child(2) span {
  right: -1px;
}

.headerIcon img {
  max-height: 1.65rem;
  width: 1.65rem;
}

.headerIcon span {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  right: -6px;
  color: #fff;
  font-size: 0.55rem;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #eb3349;
}

.sidebarToggler {
  display: flex;
}

@media (min-width: 768px) {
  .sidebarToggler {
    display: none !important;
  }
}

