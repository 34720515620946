/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */

/*
 * Brand
 * ======================================================================== */

/*
 * Typography
 * ======================================================================== */

/*
  * Layout
  * ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

/**
 * Custom application mixins available through out the app
 */

.root {
  padding-top: 20vh;
  text-align: center;
}

.title {
  font-size: 80px;
}

