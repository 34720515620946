/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */

/*
 * Brand
 * ======================================================================== */

/*
 * Typography
 * ======================================================================== */

/*
  * Layout
  * ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

/**
 * Custom application mixins available through out the app
 */

.root {
  width: 200px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  color: #4d4d4d;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 100%;
  background: linear-gradient(to right, #fff, #fff);
}

.logo a img {
  width: 184px;
  background-color: white;
  padding: 8px;
}

.nav {
  padding: 10px 0;
  overflow-y: auto;
}

